import { isObj, isNil, constructQueries } from '@/shared/utils';
import { store } from '@/store';

/**
 * If it's not a lat/lng poosition so we change address into a string
 *
 * @param {Object} addr
 */
const removeStuff = (addr) => {
	let res = '';
	if (addr.lat === 0 && addr.lng === 0) {
		res = rendAddrToStr(addr);
	} else {
		res = { lat: addr.lat, lng: addr.lng };
	}

	return res;
};

const rendAdrToLocation = (addr) => {
	if (isObj(addr)) {
		return [addr.lat, addr.lng].join(',');
	}

	return addr;
};

export const rendAddrToStr = (addr) => {
	return [addr.number, addr.street, addr.number, addr.city, addr.other, addr.state].filter((n) => n).join(', ');
};

/**
 * @param {String} or - origin
 * @param {String} des - destination
 * @param {Object} options - { windowTitle: null, travelMode: null }
 */
export const makeDirLink = (or, des, options = { windowTitle: null, travelMode: null }) => {
	const _or = removeStuff(or);
	const _des = removeStuff(des);

	// platform detection
	if (navigator.platform.indexOf('iPhone') !== -1 || navigator.platform.indexOf('iPod') !== -1) {
		// gonna check the iOversion
		// TODO: maybe better to use that helper in helper's section
		function iOSversion() {
			if (/iP(hone|od|ad)/.test(navigator.platform)) {
				// supports iOS 2.0 and later
				var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
				return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
			}
		}

		const mode = isNil(options.travelMode) ? 'DRIVING' : options.travelMode;
		const modeMap = { DRIVING: 'd', TRANSIT: 'r', BICYCLING: 'w' };

		const appleMapQueries = {
			api: 1,
			saddr: rendAdrToLocation(_or),
			daddr: rendAdrToLocation(_des),
			dirflg: isNil(modeMap[mode]) ? 'd' : modeMap[mode]
		};

		const ver = iOSversion() || [0];
		const protocol = ver[0] >= 6 ? 'maps://' : 'http://';

		window.location = protocol + `maps.apple.com/maps${constructQueries(appleMapQueries)}`;
	} else {
		const googleMapQueries = {
			api: 1,
			origin: rendAdrToLocation(_or),
			destination: rendAdrToLocation(_des),
			travelmode: isNil(options.travelMode) ? 'driving' : options.travelMode.toLowerCase()
		};

		console.log({ googleMapQueries });

		window.open(
			`https://www.google.com/maps/dir/${constructQueries(googleMapQueries)}`,
			isNil(options.windowTitle) ? 'Google map' : options.windowTitle,
			'height=670,width=992,modal=yes,alwaysRaised=yes'
		);
	}
};

/**
 * @param {Object} date
 * @return {String}
 */
export const fetchDistance = async ({ from, to, travelMode, avoidHighways, avoidTolls, unit }) => {
	return new Promise((resolve, reject) => {
		let response;
		const service = new google.maps.DistanceMatrixService();

		// const unit = unitSystem;
		const unitSystem = unit === 'imperial' ? google.maps.UnitSystem.IMPERIAL : google.maps.UnitSystem.METRIC;

		const directionsTravelMode = (key) => {
			const _c = ['WALKING', 'DRIVING', 'TRANSIT'].includes(key);
			const _k = _c ? key : 'DRIVING';
			return google.maps.DirectionsTravelMode[_k];
		};

		service.getDistanceMatrix(
			{
				origins: [from],
				destinations: [to],
				travelMode: directionsTravelMode(travelMode),
				unitSystem,
				transitOptions: {
					departureTime: new Date(1337675679473),
					modes: ['TRAIN'],
					routingPreference: 'FEWER_TRANSFERS'
				},
				avoidHighways: avoidHighways || false,
				avoidTolls: avoidTolls || false
			},
			function (resp, status) {
				if (status !== google.maps.DistanceMatrixStatus.OK) {
					response = reject(status);
				} else {
					response = resolve(resp);
				}
			}
		);
		return response;
	});
};

/**
 * @param {Number|String} lat
 * @param {Number|String} lng
 * @return {Boolean}
 */
export const validateLatLng = (lat, lng) => {
	let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');

	return pattern.test(lat) && pattern.test(lng);
};

/**
 * @param {Number|String} lat
 * @param {Number|String} lng
 * @return {Boolean}
 */
export const getReverseGeocodingData = async (lat, lng) => {
	if (!validateLatLng(lat, lng)) return;
	const options = {
		latlng: `${lat},${lng}`,
		key: process.env.VUE_APP_GOOLGE_API_KEY,
		sensor: false
	};
	const _ec = encodeURIComponent;
	const params = Object.keys(options)
		.map((key) => `${_ec(key)}=${_ec(options[key])}`)
		.join('&');
	const url = `https://maps.googleapis.com/maps/api/geocode/json?${params}`;
	const res = await fetch(url);
	return res;
};

export const recalculateDistanceValueByUnit = (value) => {
	let newValue = 0;
	const currentDistanceUnit = store.state.myProfile.distanceUnit; // text => km, miles

	if (currentDistanceUnit.toLowerCase() === 'km') {
		newValue = parseFloat(value);
	} else if (currentDistanceUnit.toLowerCase() === 'miles') {
		// i.e: km to miles
		newValue = parseFloat((parseFloat(value) / 1.61).toFixed(3));
	}

	return newValue;
};

/**
 * For database value
 */
export const recalculateDistanceValueToKm = (value) => {
	let newValue = parseFloat(value);
	const currentDistanceUnit = store.state.myProfile.distanceUnit; // text => km, miles

	if (currentDistanceUnit.toLowerCase() === 'miles') {
		// i.e: miles to km
		newValue = parseFloat((parseFloat(value) * 1.61).toFixed(3));
	}

	return newValue;
};

// /**
//  * @param {Number|String} lat
//  * @param {Number|String} lng
//  * @return {Boolean}
//  */
// export const getReverseGeocodingData = (lat, lng) => {
//   return new Promise((resolve, reject) => {
//     if (!validateLatLng(lat, lng)) {
//       return reject(new Error('Lat and Lng not valid'))
//     }

//     const latLng = new google.maps.LatLng(lat, lng)
//     console.log({ latLng })

//     const geocoder = new google.maps.Geocoder()
//     geocoder.geocode({ latLng }, (results, status) => {
//       if (status !== google.maps.GeocoderStatus.OK) return reject(status)
//       console.log(results)
//       const address = resolve(results[0].formatted_address)
//     })
//   })
// }
