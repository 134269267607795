<template>
	<errorContainer :error="error" :warning="warning">
		<transition name="slide">
			<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
				<div class="form">
					<b-row class="mt-3">
						<b-col class="p-0">
							<b-card no-body>
								<b-card-text :class="{ 'bg-color-white': $screen.width < 992 }">
									<b-row class="ml-2">
										<b-col>
											<b-form-checkbox
												id="checkbox-today-only"
												v-model="todayOnly"
												name="checkbox-today-only"
												:value="true"
												:unchecked-value="false"
												@change="handleChangeTodayOnly"
											>
												{{ FormMSG(9689689, 'Today only') }}
											</b-form-checkbox>
										</b-col>
									</b-row>
									<b-row class="mt-3">
										<b-col md="5">
											<b-input-group v-if="transportsList.length > 0">
												<b-form-input
													type="text"
													v-model="filter"
													:placeholder="FormMSG(37, 'Type to Search')"
												/><!-- @change="handleChangeFilter" -->
												<b-input-group-append class="cursor-pointer">
													<b-input-group-text class="btn-search">
														<component
															:is="getLucideIcon('Search')"
															color="#FFFFFF"
															:size="16"
															:stroke-width="2.5"
															v-if="filter.length === 0"
														/>
														<component
															:is="getLucideIcon('X')"
															color="#FFFFFF"
															:size="16"
															:stroke-width="2.5"
															@click="filter = ''"
															v-else
														/>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-col>
										<b-col md="7" class="text-right hide-on-tablet">
											<b-button @click="handleClickAddTransport" variant="outline-primary" size="xl">
												<component :is="getLucideIcon('PlusCircle')" :size="16" /> {{ FormMSG(65, 'Add transport') }}
											</b-button>
										</b-col>
									</b-row>
									<b-row class="mt-3">
										<b-col cols="12" xl="12">
											<div v-if="transportsList">
												<div v-for="(transport, i) in transportsList" class="hide-on-desktop" :key="i">
													<CollapsibleCard
														@transport:startOrStop="handleUpdateTransport"
														@transport:openMap="handleOpenNewWindow"
														@transport:delete="handleDelete"
														@transport:edit="rowClicked"
														@transport:view-passenger="handleClickViewPassenger"
														:cardData="transport"
													/>
												</div>
												<b-table
													v-if="transportsList.length > 0"
													class="hide-on-tablet"
													ref="myTable"
													selected-variant="primary"
													hover
													responsive="sm"
													:items="transportsList"
													style="text-align: left"
													:fields="transportsFields"
													:filter="filter"
													bordered
													small
													head-variant="dark"
													:empty-text="FormMSG(158, 'No document package found')"
													show-empty
													tbody-tr-class="p-2"
													@row-clicked="rowClicked"
												>
													<template #cell(departureArrivalTime)="{ item }">
														{{ rendTime(item.departureTime) ? rendTime(item.departureTime) : '' }}
														{{ rendTime(item.arrivalTime) ? ' - ' + rendTime(item.arrivalTime) : '' }}
													</template>
													<template #cell(km)="{ item }">
														{{ item.km + ' ' + distanceUnit }}
													</template>
													<template #cell(kgCoTwo)="{ item }">
														{{ rendCo2(item.kgCoTwo) }}
													</template>
													<template #cell(type)="{ item }">
														{{ rendTransportationMean(item.type) }}
													</template>
													<template #cell(routeAddress)="{ item }">
														<span v-if="item.fromAddress.street === '' && item.toAddress.street === ''"> ... </span>
														<span v-else>
															<span v-html="rendFormattedAddress(item.fromAddress)" />
															<i class="icon-arrow-right" style="color: #00a09c" />
															<span v-html="rendFormattedAddress(item.toAddress)" />
														</span>
													</template>
													<template #cell(action)="{ item }">
														<b-dropdown
															no-caret
															dropleft
															class="custom-dropdown"
															no-flip
															block
															size="sm"
															offset="25"
															variant="none"
															style="border-right: 0px !important; padding-top: 0px !important; position: relative"
														>
															<template #button-content>
																<more-vertical />
															</template>
															<b-dropdown-item-button v-if="isArrived(item) === false" @click="handleUpdateTransport(item)">
																<car /> &nbsp;
																<b v-if="isStarted(item) === false">{{ FormMSG(60, 'Start transport') }}</b>
																<b v-else> {{ FormMSG(70, 'Stop transport') }}</b>
															</b-dropdown-item-button>
															<b-dropdown-item-button
																:disabled="item.fromAddressId === 0 || item.toAddressId === 0"
																@click="handleOpenNewWindow(item)"
															>
																<map-pin /> <b>{{ FormMSG(61, 'View in map') }}</b>
															</b-dropdown-item-button>
															<b-dropdown-item-button
																:disabled="item.passenger.length === 0"
																@click="handleClickViewPassenger(item)"
															>
																<users /> &nbsp; <b>{{ FormMSG(62, 'View passengers') }}</b>
															</b-dropdown-item-button>
															<b-dropdown-item-button v-if="canRemoveTransport(item)" @click="handleDelete(item.id)"
																><trash color="tomato" /> &nbsp;
																<b style="color: tomato">{{ FormMSG(63, 'Remove') }}</b></b-dropdown-item-button
															>
														</b-dropdown>
													</template>
												</b-table>

												<b-alert v-else class="full" show variant="info">
													{{ FormMSG(786, 'No Item to show') }}
												</b-alert>
											</div>
											<b-alert v-else class="full" show variant="error">
												{{ FormMSG(798766, 'Something wrong happened during the loading') }}
											</b-alert>
										</b-col>
									</b-row>
								</b-card-text>
							</b-card>
						</b-col>
					</b-row>
				</div>
				<b-row class="footer-fixed hide-on-desktop">
					<b-col md="12">
						<b-button class="w-100 mb-2" size="lg" @click="handleClickAddTransport">
							{{ FormMSG(700, 'Add new transport') }}
						</b-button>
					</b-col>
				</b-row>
				<transport-modal
					:open="showModalTransport"
					:edit-data="currTransportToEdit"
					:isMyTransport="isMytransport"
					@transport-modal:close="handleTransportModalClose"
					@transport-modal:submit-success="handleTransportModalSubmitSuccess"
				/>
				<PassengersListModal v-model="showPassengerListModal" :passengers="passengerList" @input="handleInputPassengersListModal" />
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import { store } from '@/store/index';
import { getTransports, updatedTransport, delTransport, getPassengers } from '@/cruds/transports.crud';
import { isArrived, isStarted } from '@/modules/transports/utils';
import { MoreVertical, Car, MapPin, Users, Trash2 as Trash, Search, X } from 'lucide-vue';
import languageMessages from '@/mixins/languageMessages';
import transportMixin from '@/modules/transports/transport.mixin';
import globalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import { rendKgCo2 } from '../../../../shared/helpers';
import { mapActions, mapGetters } from 'vuex';

import TransportModal from '@/modules/transports/components/TransportModal';
import CollapsibleCard from './collapsibleCard.vue';
import PassengersListModal from '@/modules/transports/Modals/PassengersListModal';
import { makeDirLink } from '@/shared/google/helpers';
import { getAddress } from '@/cruds/address.crud';
import { TRANSPORTATION_MEAN_MENU } from '@/shared/constants';

export default {
	name: 'MyTransportFocusPage',
	components: { MoreVertical, Car, MapPin, Users, Trash, Search, X, TransportModal, CollapsibleCard, PassengersListModal },
	mixins: [languageMessages, transportMixin, globalMixin],
	data() {
		return {
			isMytransport: true,
			error: {},
			warning: '',
			filter: '',
			currentPage: 1,
			perPage: 100,
			selectMode: 'single',
			selectedColor: 'primary',
			transportsList: [],
			isTransportLoading: false,
			userId: 0,

			currStatus: 'home',
			todayOnly: true,

			showModalTransport: false,
			currTransportIdToEdit: 0,
			currTransportToEdit: {},

			showPassengerListModal: false,
			passengerList: []
		};
	},
	async created() {
		await this.setUserId();
		await this.initTransports();
	},
	computed: {
		...mapGetters({
			myLocationToday: 'global-filter/myLocationToday'
		}),

		/**
		 * @return {Array}
		 */
		transportsFields() {
			return [
				{
					key: 'date',
					label: this.FormMSG(5467, 'Date'),
					sortable: true,
					formatter: (v, t, root) => moment(new Date(root.departureDate)).format('DD/MM/YYYY')
				},
				{
					key: 'departureArrivalTime',
					label: this.FormMSG(156, 'Departure . Arrival times'),
					sortable: true
				},
				{
					key: 'extraInfo',
					label: this.FormMSG(50, 'Description')
				},
				{
					key: 'type',
					label: this.FormMSG(51, 'Transportation mean'),
					sortable: true
				},
				{
					key: 'routeAddress',
					label: this.FormMSG(894498, 'Route'),
					sortable: false,
					formatter: (v) => v
				},
				{
					key: 'vehicule',
					label: this.FormMSG(59614, 'Vehicule'),
					sortable: true,
					formatter: (v) => (v.name === '' && v.numberPlate === '' ? '...' : `${this.capitalize(v.name)} (${v.numberPlate})`)
				},
				{
					key: 'km',
					label: this.FormMSG(53, 'Distance'),
					sortable: true
				},
				{
					key: 'kgCoTwo',
					label: this.FormMSG(54, 'CO2'),
					sortable: true
				},
				{
					key: 'action',
					label: this.FormMSG(55, 'Action')
				}
			];
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		}
	},
	methods: {
		...mapActions({
			setCurrentTransportId: 'transport/setCurrentTransportId',
			setCurrentTransport: 'transport/setCurrentTransport',
			setMyLocationToday: 'global-filter/setMyLocationToday'
		}),

		async handleOpenNewWindow(item) {
			const origin = await getAddress(item.fromAddressId);
			const destination = await getAddress(item.toAddressId);

			window.open(makeDirLink(origin, destination), this.FormMSG(213421, 'Google map link'), 'height=670,width=970,modal=yes,alwaysRaised=yes');
		},

		handleInputPassengersListModal(payload) {
			this.showPassengerListModal = payload;
		},

		handleClickViewPassenger(item) {
			this.showPassengerListModal = true;
			this.passengerList = item.passenger;
		},

		canRemoveTransport(item) {
			if (parseInt(item.userId) === parseInt(item.createdBy)) {
				return true;
			}

			return false;
		},

		async handleTransportModalSubmitSuccess() {
			this.currTransportToEdit = {};
			this.currTransportIdToEdit = 0;
			await this.initTransports();
		},
		handleTransportModalClose() {
			this.currTransportIdToEdit = 0;
			this.currTransportToEdit = {};
			this.showModalTransport = false;
		},
		async handleClickAddTransport() {
			const res = await updatedTransport(0, {
				departureDate: new Date(),
				reason: 0,
				userId: store.userID()
			});

			if (this.$screen.width > 567) {
				this.currTransportToEdit = {
					...res.AddUpdTransport
				};
				this.isMytransport = true;
				this.showModalTransport = true;
			} else {
				this.setCurrentTransportId(parseInt(res.AddUpdTransport.id));
				this.setCurrentTransport({
					...res.AddUpdTransport
				});

				this.$router.push({
					path: '/mytransport/mobile/add'
				});
			}
		},
		async handleDelete(id) {
			const action = async () => {
				await delTransport(id);
				await this.initTransports();
			};

			this.confirmModal(action, this.FormMSG(874, 'Are you sure ?'));
		},
		rendTransportationMean(transportType) {
			console.log(transportType);
			if ([1, 2, 3].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.BOAT, transportType);
			}
			if ([4, 5].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.BUS, transportType);
			}
			if ([6, 7, 8].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.CAR, transportType);
			}
			if ([17, 18, 19, 20, 21].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.FREIGHT, transportType);
			}
			if ([9, 10, 11, 12, 13, 14, 15, 16].includes(transportType)) {
				return this.GetTextFromMenuNumberAndMenuValue(TRANSPORTATION_MEAN_MENU.OTHER, transportType);
			}
		},
		rendCo2(co2) {
			return rendKgCo2(co2);
		},
		rendTime(time) {
			if (time === '0001-01-01T00:00:00Z') return;
			return moment(new Date(time)).format('HH:mm');
		},
		async handleChangeTodayOnly(payload) {
			this.setMyLocationToday(payload);
			this.todayOnly = payload;
			this.isTransportLoading = true;
			await this.initTransports();
			this.isTransportLoading = false;
		},
		async initTransports() {
			this.todayOnly = this.myLocationToday;
			this.isTransportLoading = true;
			this.transportsList = await getTransports(this.userId, this.todayOnly);
			this.isTransportLoading = false;
		},

		async setUserId() {
			this.userId = await store.userID();
		},

		/**
		 * @param {Object} transport
		 * @return {Boolean}
		 */
		isArrived(transport) {
			if (transport.effArrivalTime === '0001-01-01T00:00:00Z') {
				return false;
			}

			return true;
			// let arrival = transport.arrivalTime;
			// if (arrival == '0001-01-01T00:00:00Z') {
			// 	return false;
			// } else {
			// 	return true;
			// }
		},

		/**
		 * @param {Object} transport
		 * @return {Boolean}
		 */
		isStarted(transport) {
			if (transport.effDepartureTime === '0001-01-01T00:00:00Z') {
				return false;
			}

			return true;
			// let departure = moment(transport.departureTime).format('HH:mm');
			// if (departure == '0001-01-01T00:00:00Z') {
			// 	return false;
			// } else {
			// 	return true;
			// }
		},
		async handleUpdateTransport(data) {
			if (!this.isStarted(data)) {
				await this.handleStartEffTime(parseInt(data.id));
			} else if (this.isStarted(data) && !this.isArrived(data)) {
				await this.handleEndEffTime(+data.id);
			}
			// if (!isStarted(data) && isArrived(data)) {
			// 	await this.handleStartEffTime(data.id);
			// } else {
			// 	await this.handleEndEffTime(data.id);
			// }
		},
		/**
		 * @param {String|Number} transportId
		 */
		async handleStartEffTime(transportId) {
			const action = async () => {
				const dateToSend = new Date(new Date().toUTCString());
				await updatedTransport(transportId, {
					departureTime: dateToSend,
					effDepartureTime: dateToSend
				});

				this.createToastForMobile(this.FormMSG(490, 'Success'), this.FormMSG(489, 'Transport updated successfully!'));

				await this.initTransports();
			};

			this.confirmModal(action, this.FormMSG(492, 'You are about to start, are you sure ?'));
		},

		/**
		 * @param {String|Number} transportId
		 */
		async handleEndEffTime(transportId) {
			const action = async () => {
				const dateToSend = new Date(new Date().toUTCString());
				await updatedTransport(transportId, {
					arrivalTime: dateToSend,
					effArrivalTime: dateToSend
				});

				this.createToastForMobile(this.FormMSG(490, 'Success'), this.FormMSG(489, 'Transport updated successfully!'));

				await this.initTransports();
			};

			this.confirmModal(action, this.FormMSG(488, 'You are about to end, are you sure ?'));
		},

		/**
		 * @param {Object} transport
		 */
		rowClicked(transport) {
			if (this.$screen.width > 567) {
				this.currTransportIdToEdit = parseInt(transport.id, 10);
				this.currTransportToEdit = transport;
				this.showModalTransport = true;
			} else {
				this.setCurrentTransportId(parseInt(transport.id, 10));
				this.setCurrentTransport(transport);

				this.$router.push({
					path: '/mytransport/mobile/update/' + transport.id
				});
			}

			// this.$router.push({
			// 	name: 'My transport details',
			// 	params: { id: transport.id }
			// });
		}
	}
};
</script>
<style>
.custom-dropdown.b-dropdown-open {
	position: absolute;
	bottom: 100%;
}
</style>
